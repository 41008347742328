import {Door, Image, Suffix} from 'components/customer/Materials/entity';

export interface LegacyDoorInterface {
    advanced?: boolean;
    defaultBorderWidthBottom?: number;
    defaultBorderWidthLeft?: number;
    defaultBorderWidthRight?: number;
    defaultBorderWidthTop?: number;
    defaultEdgeBrand?: number;
    defaultEdgeColour?: number;
    defaultEdgeFinish?: string;
    defaultRailsHorizontalHeight?: number;
    defaultRailsVerticalWidth?: number;
    filterName?: string;
    id?: number;
    image?: string;
    lockDefaultEdgeType?: boolean;
    maximumHeight?: number;
    maximumRailsHorizontalHeight?: number;
    maximumRailsVerticalWidth?: number;
    maximumWidth?: number;
    minimumBorderWidthBottom?: number;
    minimumBorderWidthLeft?: number;
    minimumBorderWidthRight?: number;
    minimumBorderWidthTop?: number;
    minimumDistanceLeftRight?: number;
    minimumDistanceTopBottom?: number;
    minimumHeight?: number;
    minimumRailsHorizontalHeight?: number;
    minimumRailsVerticalWidth?: number;
    minimumWidth?: number;
    name?: string;
    door_name?: string;
    setAsDefaultEdgeType?: boolean;
    suffixId?: number;
    suffixName?: string;
    defaultEdgeFinishTop?: number;
    defaultEdgeFinishBottom?: number;
    defaultEdgeFinishLeft?: number;
    defaultEdgeFinishRight?: number;
    defaultEdgeFinishJoin?: number;
    isLockedEdgeFinish?: boolean;
    isDefaultEdgeFinish?: boolean;
    hidden?: boolean;
    suffix_id?: number;
    suffix_name?: string;
    suffix?: string;
    filter_name?: string;
    is_advanced?: boolean;
    default_colour?: string;
    set_as_default_edge_finish?: number;
    lock_default_edge_finish?: number;
    default_edge_finish_bottom?: number;
    default_edge_finish_left?: number;
    default_edge_finish_right?: number;
    default_edge_finish_top?: number;
    default_edge_finish_join?: number;
    set_as_default_edge_type?: number | boolean;
    lock_default_edge_type?: number | boolean;
    minimum_width?: number;
    maximum_width?: number;
    minimum_height?: number;
    minimum_height_drawer?: number;
    minimum_border_width_top?: number;
    minimum_border_width_bottom?: number;
    minimum_border_width_left?: number;
    minimum_border_width_right?: number;
    default_border_width_top?: number;
    default_border_width_bottom?: number;
    default_border_width_left?: number;
    default_border_width_right?: number;
    minimum_distance_top_bottom?: number;
    minimum_distance_left_right?: number;
    minimum_rails_horizontal_height?: number;
    maximum_rails_horizontal_height?: number;
    minimum_rails_vertical_width?: number;
    maximum_rails_vertical_width?: number;
    default_rails_horizontal_height?: number;
    default_rails_vertical_width?: number;
    image_name?: string;
    maximum_height?: number;
}

export const mapDoor = (door: LegacyDoorInterface): Door => {
    return <Door>{
        id: door.id,
        manufacturer_id: 0,
        suffix_id: door.suffixId,
        name: door.name,
        filter_name: door.filterName,
        suffix_name: door.suffixName,
        handling_cost: 0,
        area_handling_cost: 0,
        machining_cost: 0,
        area_machining_cost: 0,
        unit_cost: 0,
        assembly_cost: 0,
        area_assembly_cost: 0,
        is_outsourced: false,
        is_advanced: door.advanced,
        is_deleted: false,
        is_default_edge_type: door.setAsDefaultEdgeType,
        is_default_edge_type_locked: door.lockDefaultEdgeType,
        default_brand_id: door.defaultEdgeBrand,
        default_material_id: door.defaultEdgeColour,
        default_finish: door.defaultEdgeFinish,
        minimum_width: door.minimumWidth,
        maximum_width: door.maximumWidth,
        minimum_height: door.minimumHeight,
        maximum_height: door.maximumHeight,
        minimum_drawer_height: 0,
        minimum_border_width_top: door.minimumBorderWidthTop,
        minimum_border_width_bottom: door.minimumBorderWidthBottom,
        minimum_border_width_left: door.minimumBorderWidthLeft,
        minimum_border_width_right: door.minimumBorderWidthRight,
        default_border_width_top: door.defaultBorderWidthTop,
        default_border_width_bottom: door.defaultBorderWidthBottom,
        default_border_width_left: door.defaultBorderWidthLeft,
        default_border_width_right: door.defaultBorderWidthRight,
        minimum_distance_top_bottom: door.minimumDistanceTopBottom,
        minimum_distance_left_right: door.minimumDistanceLeftRight,
        minimum_rails_horizontal_height: door.minimumRailsHorizontalHeight,
        maximum_rails_horizontal_height: door.maximumRailsHorizontalHeight,
        minimum_rails_vertical_width: door.minimumRailsVerticalWidth,
        maximum_rails_vertical_width: door.maximumRailsVerticalWidth,
        default_rails_horizontal_height: door.defaultRailsHorizontalHeight,
        default_rails_vertical_width: door.defaultRailsVerticalWidth,
        default_edge_finish_top: door.defaultEdgeFinishTop,
        default_edge_finish_bottom: door.defaultEdgeFinishBottom,
        default_edge_finish_left: door.defaultEdgeFinishLeft,
        default_edge_finish_right: door.defaultEdgeFinishRight,
        default_edge_finish_join: door.defaultEdgeFinishJoin,
        is_default_edge_finish: door.isDefaultEdgeFinish,
        is_locked_edge_finish: door.isLockedEdgeFinish,
        is_hidden: door.hidden,
        image: {
            id: 0,
            type_id: 0,
            folder_id: 0,
            name: `/${door.image}`,
            type_name: '',
        } as Image,
        suffix: {
            id: door.suffixId,
            name: door.suffixName,
        } as Suffix,
    };
};

export const mapDoorBack = (door: Door): LegacyDoorInterface => {
    return <LegacyDoorInterface>{
        id: door.id,
        advanced: door.is_advanced,
        minimumWidth: door.minimum_width,
        maximumWidth: door.maximum_width,
        minimumHeight: door.minimum_height,
        minimumHeightDrawer: door.minimum_drawer_height,
        maximumHeight: door.maximum_height,
        minimumBorderWidthTop: door.minimum_border_width_top,
        minimumBorderWidthBottom: door.minimum_border_width_bottom,
        minimumBorderWidthLeft: door.minimum_border_width_left,
        minimumBorderWidthRight: door.minimum_border_width_right,
        defaultBorderWidthTop: door.default_border_width_top,
        defaultBorderWidthBottom: door.default_border_width_bottom,
        defaultBorderWidthLeft: door.default_border_width_left,
        defaultBorderWidthRight: door.default_border_width_right,
        minimumDistanceTopBottom: door.minimum_distance_top_bottom,
        minimumDistanceLeftRight: door.minimum_distance_left_right,
        minimumRailsHorizontalHeight: door.minimum_rails_horizontal_height,
        maximumRailsHorizontalHeight: door.maximum_rails_horizontal_height,
        minimumRailsVerticalWidth: door.minimum_rails_vertical_width,
        maximumRailsVerticalWidth: door.maximum_rails_vertical_width,
        lockDefaultEdgeType: door.is_default_edge_type_locked,
        defaultEdgeBrand: door.default_brand_id,
        defaultEdgeColour: door.default_material_id,
        defaultEdgeFinish: door.default_finish,
        suffixId: door.suffix ? door.suffix.id : null,
        suffixName: door.suffix ? door.suffix.name : null,
        defaultEdgeFinishTop: door.default_edge_finish_top,
        defaultEdgeFinishBottom: door.default_edge_finish_bottom,
        defaultEdgeFinishLeft: door.default_edge_finish_left,
        defaultEdgeFinishRight: door.default_edge_finish_right,
        defaultEdgeFinishJoin: door.default_edge_finish_join,
        isDefaultEdgeFinish: door.is_default_edge_finish,
        isLockedEdgeFinish: door.is_locked_edge_finish,
        hidden: door.is_hidden,
    };
};
